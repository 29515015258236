.logo {
  float: left;

}

.header {
  background-color: white;
}

.title {
  float: right;
  font-weight: 800;
}

.text-white {
  color: white;
  font-size: 20px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.button-primary {
  background: #00344d;
  border-color: #00344d;
  color: white;
  font-size: 18px;
}

.ant-btn-primary {
  background: #00344d !important;
  border-color: #00344d !important;
  color: white;
}

.transparent-background {
  background-color: rgb(0 0 0 / 45%);
  box-shadow: 2px 2px 50px rgb(38 63 77 / 10%);
}

.blur {
  backdrop-filter: blur(1px) saturate(40%);
}

.xmas-image {
  height: 140px;
  width: 140px
}

.calendar .day {
  transform: translate3d(0px, 0px, 0px);
  position: relative;
}

.calendar input {
  display: none;
}

.calendar label {
  perspective: 1000px;
  transform-style: preserve-3d;
  cursor: pointer;
  display: flex;
  min-height: 100%;
  width: 100%;
  height: calc(85vw / 3);
  position: relative;
}

@media only screen and (min-width: 576px) {
  .calendar label {
    height: 175px;
  }
}

.calendar .door {
  width: 100%;
  transform-style: preserve-3d;
  transition: all 300ms;
  border: 2px dashed rgba(0, 0, 0, 0.6);
  border-radius: 0.6rem;
  transform-origin: 0% 50%;
}

.calendar .door div {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 3em;
  font-weight: bold;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2);
}

.calendar .door .back {
  background: linear-gradient(to right, #384044, #FFF);
  transform: rotateY(-180deg);
}

.calendar label:hover .door {
  border-color: #00344d;
}

.calendar :checked+.door {
  transform: rotateY(-120deg);
  border-color: #00344d;
  border-style: solid;
}

.calendar .inside {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.6rem;
  overflow: hidden;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.calendar :checked~.inside {
  opacity: 1;
}

.whiteSpace {
  white-space: pre-wrap;
}

/* Show in Large desktops and laptops */
@media (min-width: 1200px) {

  .App {
    background-image: url(../Images/background-image.jpg);
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
    min-height: 92vh;
    
  }

}

/*Hide in Other Small Devices */


/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

  .App {
    background-image: none;
    background-color: rgb(87 97 120);
    min-height: 96vh;
  }

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

  .App {
    background-image: none;
    background-color: rgb(87 97 120);
    min-height: 96vh;
  }
  .xmas-image{
    height: 100%;
    width:100%
  }

}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

  .App {
    background-image: none;
    background-color: rgb(87 97 120);
    min-height: 96vh;
  }

}

/* Portrait phones and smaller */
@media (max-width: 480px) {

  .App {
    background-image: none;
    background-color: rgb(87 97 120);
    min-height: 96vh;
  }
  .xmas-image{
    height: 100px;
    width:80px
  }

}